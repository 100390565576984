import {
  computed, reactive, ref, watch,
} from '@vue/composition-api'
import store from '@/store'
import useProduct from '@/views/product/useProduct'
import util from '@/global/util'
import ProductModal from '@/modal/productModal'
import constant from '@/global/const'

export default function useProductOfficeCreate() {
  const {
    productMenuType,
    productMenuTypeVI,
    renderProductOfficeTitle,
    renderProductOfficeContent,
    //
    checkProductUnique,
    createProduct,
    fetchProduct,
    updateProduct,
  } = useProduct()

  const duplicateError = ref(null)
  const formBaseStatus = ref([])
  const product = ProductModal()

  const routeName = computed(() => store.state.app.currentRouteName)
  const districts = computed(() => store.state.app.districts)
  const wards = computed(() => store.state.app.wards)
  const streets = computed(() => store.state.app.streets)
  const VAT = computed(() => store.state.app.VAT)

  const fakeDataProductOffice = () => {
    console.log('fakeDataProductOffice')
    setTimeout(() => {
      product.name = 'Tòa nhà Mong Lung Linh'
      product.province_id = 1
      product.house_number = '12B'
      product.direction = 'dong'
      product.class = 'a'
      product.floor_number = 3
      product.floor_area = '120,220,430,300'
      product.input_price = 2000
      product.service_fee = 20
      product.price_description = '20 + 15$'
      product.price = 2320
      product.host_name = 'dev test'
      product.host_phone1 = '0394829384'
      product.commission = 'TL'
      product.input_tl = 'Tỉ lệ thỏa thuận'
      product.is_rent_all_apartment = true
      product.car_parking_fee = 10000
      product.moto_parking_fee = 5000
      product.overtime_fee = 15000
      product.overtime_fee_type = 'hour'
      product.electricity_fee = 4000
      product.electricity_fee_type = 'kWh'
      product.deposit_time = 2
      product.pay_time = 4
      product.lease_term = 3
      product.type = 'van-phong'
      product.decor_time = 5
      product.basement_number = 2
      product.elevator_number = 3
    }, 1000)
  }

  watch(() => product.floor_area, n => {
    if (!n) return
    const arrs = n.split(',').filter(item => item).map(item => item.trim())
    const rentalArea = reactive([])
    arrs.forEach(item => {
      rentalArea.push({
        floor: '',
        area: item,
      })
    })
    product.rental_area = ref(rentalArea)
  })

  watch(() => product.electricity_fee_type, n => {
    if (n === 'phi-nha-nuoc') {
      product.electricity_fee = null
    }
  })
  watch(() => product.overtime_fee_type, n => {
    if (n === 'thoa-thuan') {
      product.overtime_fee = null
    }
  })
  watch(() => product.province_id, n => {
    if (!n) return
    store.dispatch('app/fetchDistricts', { province_id: n })
  })
  watch(() => product.district_id, n => {
    if (!n || !product.province_id) return
    store.dispatch('app/fetchWards', { province_id: product.province_id, district_id: n })
    store.dispatch('app/fetchStreets', { province_id: product.province_id, district_id: n })
  })
  watch([() => product.input_price, () => product.service_fee], ([i, s]) => {
    const val = (parseFloat(i) + parseFloat(s)) * (1 + parseFloat(VAT.value / 100)) || 0
    product.price_description = `${i} USD + ${s} USD + VAT(${VAT.value}%)`
    product.price = parseFloat(val.toFixed(1))
  })
  watch(() => product.transfer_price, n => {
    product.transferPriceDisplay = `${util.formatNumber(n || 0)} ${constant.CURRENCY.find(item => item.value === (product.transfer_price_currency || 'usd'))?.text?.toUpperCase()}`
  })

  return {
    duplicateError,
    formBaseStatus,
    productMenuType,
    productMenuTypeVI,
    routeName,
    districts,
    wards,
    streets,
    product,
    fakeDataProductOffice,
    renderProductOfficeTitle,
    renderProductOfficeContent,
    //
    checkProductUnique,
    fetchProduct,
    createProduct,
    updateProduct,
  }
}
